import {
  FontWeight,
  MoneyText,
  MoneyTextVariant,
  Text,
  TypePreset,
  XYGrid,
} from "@gocardless/flux-react";
import { Currency } from "src/common/currencies";
import { RequiredActionsDetails } from "@gocardless/api/dashboard/types";
import { useI18n } from "src/technical-integrations/i18n";

import { verificationDetails } from "./constants";

interface BankAccountDetailsProps {
  details: RequiredActionsDetails;
}

const BankAccountDetails: React.FC<BankAccountDetailsProps> = ({ details }) => {
  const [locale] = useI18n();

  return (
    <XYGrid templateColumns={["1fr", "1fr 1fr"]}>
      {verificationDetails()?.map(([title, key]) => {
        const currency = details.currency as Currency;
        const value = details[key as keyof RequiredActionsDetails];

        return value
          ? [
              <Text
                key={`${key}-title`}
                preset={TypePreset.Body_02}
                spaceBelow={0.25}
              >
                {title}
                {":"}
              </Text>,
              <Text
                className="fs-mask"
                key={`${key}-value`}
                preset={TypePreset.Code_02}
                weight={FontWeight.Medium}
                spaceBelow={1}
              >
                {key === "amount" ? (
                  <MoneyText
                    amount={Number(details[key] || 0) / 100 ?? 0.01}
                    currency={currency}
                    locale={locale}
                    format="short"
                    variant={MoneyTextVariant.Flat}
                  />
                ) : (
                  value
                )}
              </Text>,
            ]
          : null;
      })}
    </XYGrid>
  );
};

export default BankAccountDetails;
